import React, { createContext, useState, useEffect } from 'react';

// Create the UserContext
export const UserContext = createContext({
  user: null,
  saveUser: () => {},
  clearUser: () => {}
});

// UserProvider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user from localStorage on initial render
  useEffect(() => {
    try {
      const savedUser = localStorage.getItem('user');
      if (savedUser) {
        const parsedUser = JSON.parse(savedUser);
        setUser(parsedUser);
      }
    } catch (error) {
      console.error('Error parsing user data from localStorage:', error);
      localStorage.removeItem('user');
    }
  }, []); // Empty dependency array ensures this runs only on mount

  // Save user to state and localStorage
  const saveUser = (userData) => {
    try {
      // Validate userData if needed
      if (userData) {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      }
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  // Clear user from state and localStorage
  const clearUser = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  // Provide context value
  const contextValue = {
    user,
    saveUser,
    clearUser
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

// Optional: Custom hook for easier context consumption
export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};