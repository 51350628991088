import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/UserContext.jsx";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { toast } from "sonner";

const useAuth = () => {
  const navigate = useNavigate();
  const { saveUser, clearUser, user } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isInitialLoad = useRef(true);
  const isRefreshingToken = useRef(false);
  const refreshCooldown = useRef(false);

  const API_URL = import.meta.env.VITE_API_URL;


  // Axios instance with memoization and token interceptor
  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_URL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Add request interceptor to include access token from local storage
    instance.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      // console.log("access-token : "+accessToken);

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
  
      if (refreshToken) {
        config.headers["X-Refresh-Token"] = refreshToken;
      }

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    return instance;
  }, [API_URL]);

  const clearAuthData = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    clearUser();
    setIsAuthenticated(false);
  }, [clearUser]);

  const logout = useCallback(async () => {
    try {
      setIsLoading(true);
      await api.post("/api/auth/logout");
      // console.log("Logout successful");
    } catch (error) {
      // console.error("Error during logout:", error);
      toast.error("Error during logout:", error);
    } finally {
      clearAuthData();
      setIsLoading(false);
      navigate("/login");
    }
  }, [navigate, api, clearAuthData]);

  const refreshAccessToken = useCallback(async () => {
    // console.log("refresh-token is started...");
    if (isRefreshingToken.current || refreshCooldown.current) {
      return false;
    }

    isRefreshingToken.current = true;
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      // console.log("refresh-token : "+refreshToken);

      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const response = await api.post("/api/refreshToken", {}, {
        headers: {
          'X-Refresh-Token': refreshToken
        }
      });
      
      if (response.status === 200) {

        const newAccessToken = 
      response.headers["x-access-token"] || 
      response.headers["X-Access-Token"];
    
    const newRefreshToken = 
      response.headers["x-refresh-token"] || 
      response.headers["X-Refresh-Token"];

        if (newAccessToken && newRefreshToken) {
        //   console.log("new -access-token : "+newAccessToken);
        // console.log("new-refresh-token : "+newRefreshToken);
        // console.log("user Data : "+user);
          localStorage.setItem("accessToken", newAccessToken);
          localStorage.setItem("refreshToken", newRefreshToken);
        }

        // console.log("Token refresh successful");
        return true;
      }
      return false;
    } catch (error) {
      // console.error("Error refreshing token:", error);
      toast.error("Error refreshing token:", error);
      if (error.response?.status === 401) {
        await logout();
      }
      return false;
    } finally {
      isRefreshingToken.current = false;
      refreshCooldown.current = true;
      setTimeout(() => {
        refreshCooldown.current = false;
      }, 5000); // 5-second cooldown
    }
  }, [api, logout]);

  const checkAuthStatus = useCallback(async () => {
    if (!isInitialLoad.current) return;

    isInitialLoad.current = false;
    try {
      // console.log("Verifying user status...");
      setIsLoading(true);

      const accessToken = localStorage.getItem("accessToken");
      const user = localStorage.getItem("user");

      // console.log("check auth status access-token : "+accessToken);
      // console.log("check auth status user : "+user);

      if (accessToken && user) {
        const response = await api.get("/api/auth/verifyUser");
        if (response.status === 200 && response.data.user) {
          saveUser(response.data.user);
          setIsAuthenticated(true);
        } else {
          clearAuthData();
        }
      } else {
        clearAuthData();
      }
    } catch (error) {
      toast.error("Error checking authentication status:", error);
      // console.error("Error checking authentication status:", error);
      clearAuthData();
    } finally {
      setIsLoading(false);
    }
  }, [api, saveUser, clearAuthData]);

  useEffect(() => {
    const refreshQueue = [];
    let isRefreshing = false;

    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (isRefreshing) {
            try {
              await new Promise((resolve, reject) => {
                refreshQueue.push({ resolve, reject });
              });
              return api(originalRequest);
            } catch (refreshError) {
              return Promise.reject(refreshError);
            }
          }

          isRefreshing = true;

          try {
            const refreshed = await refreshAccessToken();
            if (refreshed) {
              refreshQueue.forEach(({ resolve }) => resolve());
              refreshQueue.length = 0; // Clear the queue
              return api(originalRequest);
            } else {
              refreshQueue.forEach(({ reject }) => reject());
              refreshQueue.length = 0;
              await logout();
              return Promise.reject(error);
            }
          } catch (refreshError) {
            refreshQueue.forEach(({ reject }) => reject());
            refreshQueue.length = 0;
            await logout();
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }

        return Promise.reject(error);
      }
    );

    checkAuthStatus();

    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [checkAuthStatus, logout, refreshAccessToken, api]);

  return {
    isAuthenticated,
    isLoading,
    user,
    logout,
    api,
  };
};

export default useAuth;